.footer {
    background-color: #FBF7ED;
    padding: 10vh 10vw;
    text-align: right;
}

.footer * {
    color: #1A1B1F;
}

.socials {
    display: inline-flex;
    margin-bottom: 5vh;
}

.socials a {
    margin-left: 30px;
    font-size: 20px;
}