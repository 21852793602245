.navBar {
  background-color: #195D50;
  padding: 20px;
}

.navbar-brand {
  color: white !important;
  font-weight: bold;
}

.link {
  color: white !important;
  margin: 0 1.5vw;
  font-size: 1.0em;
}

.active {
  text-decoration: underline !important;
}