#projects {
    text-align: center;
}

section p, section h4 {
    color: #C2C2C2;
}

.content {
    color: #1A1B1F;
    background-color: #FBF7ED;
}

.content p {
    color:#1A1B1F;
}

button {
    border: none;
    padding: 8px 12px;
    font-size: 16px;
}

.button1 {
    border-radius: 4px;
    background-color: #FBF7ED;
    color: #004C3F;
}

.button2 {
    border: 1px solid #FBF7ED;
    border-radius: 4px;
    background-color: #004C3F;
    color: #FBF7ED;
}

a {
    text-decoration: none !important;
    color: #004C3F !important;
}

button:hover, a:hover {
    opacity: 0.7;
}

.container h4, .container p {
    color: #1A1B1F;
}

.cardClass {
    padding: 20px;
    width: 18rem;
}

.cardStyle {
    background-color: #195D50;
    max-width: 256px;
    text-align: left;
}

.cardContent {
    padding-left: 10px;
    text-align: left;
}

.textStyle {
    padding-top: 20px;
    margin-bottom: 0;
}

.textStyle3 {
    margin-bottom: 0;
}

.profImg {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.subsubTitle {
    text-decoration: overline;
    margin-bottom: 0px;
    margin-top: 10px;
    text-shadow: 1px 1px 3px white;
}

.tagline {
    padding-bottom: 20px;
    font-style: italic;
}

.linkBtn {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
}

.listStudents {
    margin-left: 20px; 
}