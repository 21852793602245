#team {
    text-align: center;
}

section p, section h4 {
    color: #C2C2C2;
}

.content {
    color: #1A1B1F;
    background-color: #FBF7ED;
}

.content p {
    color:#1A1B1F;
}

button {
    border: none;
    padding: 8px 12px;
    font-size: 16px;
}

.button1 {
    border-radius: 4px;
    background-color: #FBF7ED;
    color: #004C3F;
}

.button2 {
    border: 1px solid #FBF7ED;
    border-radius: 4px;
    background-color: #004C3F;
    color: #FBF7ED;
}

a {
    text-decoration: none !important;
    color: #004C3F !important;
}

button:hover, a:hover {
    opacity: 0.7;
}

.team {
    margin: 5vh 0;
    align-items: center;
}

.cardClass {
    padding: 20px;
    width: 18rem;
}

#cardStyle {
    background-color: #195D50;
    /* max-width: 256px; */
    text-align: left;
    height: 100%;
}

.cardContent {
    padding-left: 10px;
    text-align: left;
}

.textStyle {
    padding-top: 20px;
    margin-bottom: 0;
}

.textStyle3 {
    margin-bottom: 0;
}

.profImg {
    width: 200px;
    height: 200px;
    object-fit: cover;
}
