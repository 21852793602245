section {
    padding: 10vh 10vw;
    color: #FBF7ED;
    background-color: #004C3F;
}

section p, section h4 {
    color: #C2C2C2;
}

.content {
    color: #1A1B1F;
    background-color: #FBF7ED;
}

.content p {
    color:#1A1B1F;
}

button {
    border: none;
    padding: 8px 12px;
    font-size: 16px;
}

.button1 {
    border-radius: 4px;
    background-color: #FBF7ED;
    color: #004C3F;
}

.button2 {
    border: 1px solid #FBF7ED;
    border-radius: 4px;
    background-color: #004C3F;
    color: #FBF7ED;
}

a {
    text-decoration: none !important;
    color: #004C3F !important;
}

button:hover, a:hover {
    opacity: 0.7;
}

.r {
    margin: 5vh 0;
    align-items: center;
}

.container {
    padding: 20px 30px;
    background-color: #FBF7ED;
    box-shadow: 0px 2px 4px -2px #FBF7ED, 0px 4px 4px -2px #FBF7ED;
    border-radius: 16px;
    margin: 0 2vw !important;
}

.container h4, .container p {
    color: #1A1B1F;
}

.img {
    text-align: center;
}

/*For phone screens*/
@media screen and (max-width: 700px) {
    .r {
        text-align: center;
        flex-direction: column;
    }

    .img img {
        width: 100%;
        margin-bottom: 30px;
    }

    section {
        padding: 5vh 8vw;
    }

    .container {
        margin: 2vh 0 !important;
    }
}